import { ReactElement } from "react";
import 'react-horizontal-scrolling-menu/dist/styles.css';
import './scrollbar.scss';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import useDrag from "./useDrag";


type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;

function onWheel(apiObj: scrollVisibilityApiType, ev: React.WheelEvent): void {
    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;
    if (isThouchpad)
        ev.stopPropagation();
}

function HorizontalScrollMenu(props: {children: ReactElement | ReactElement[], LeftArrow: any, RightArrow: any}){
    const { dragStart, dragStop, dragMove } = useDrag();
    const handleDrag = ({ scrollContainer }: scrollVisibilityApiType) => (ev: React.MouseEvent) =>
        dragMove(ev, (posDiff) => {
        if (scrollContainer.current) {
            scrollContainer.current.scrollLeft += posDiff;
        }
    });

    return (
        <ScrollMenu LeftArrow={props.LeftArrow} RightArrow={props.RightArrow} onWheel={onWheel} onMouseDown={() => dragStart} onMouseUp={() => dragStop} onMouseMove={handleDrag}>
            {props.children}
        </ScrollMenu>
    )
}

export default HorizontalScrollMenu;