import styles from './information.module.scss';
import RenderOn from '../../../Components/RenderOn/RenderOn';

function Information(){
    return(
        <div className={styles.information_box}>
            <div className={styles.info1_box} data-aos="fade-right">
                <p className={styles.info1_title}>Earning PICTOS</p>
                <div className={styles.info1_inner_box}>
                    <div className={styles.info1_icon}/>
                    <p className={styles.info1_text1}>By completing quests you accumulate in-game currency, which can be used to level up your gear or exchanged for real</p>
                </div>
                <p className={styles.info1_text2}>money. Plus, being part of the PICTO community enhances your photographic journey, making it even more gratifying. </p>
            </div>
            <RenderOn platform='mobile'>
                <div className={styles.info1_box} data-aos="fade-left">
                    <p className={styles.info1_title}>Why It’s Exceptional?</p>
                    <div className={styles.info1_inner_box}>
                        <p className={styles.info2_text1}>Say goodbye to regular photo apps! PICTOGRAPHS employs intelligent AI technology to make photo quests entertaining and</p>
                        <div className={styles.info2_icon}/>
                    </div>
                    <p className={styles.info2_text2}>straightforward. Each snap you take with Pictographs is like a strategic move in a game, tightly linked with in-game rewards. Get ready for an entirely new photography experience!</p>
                </div> 
            </RenderOn>
            <RenderOn platform='desktop'>
                <div className={styles.info2} data-aos="fade-up">
                    <p >Be ready to transform your photos into treasures!<br/>Join PICTOGRAPHS now and embark on your photo adventure!</p>
                </div>
            </RenderOn>
            
        </div>
    );
}

export default Information;