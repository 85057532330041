import { useEffect } from "react";
import AmbassadorPage from "./AmbassadorPage/AmbassadorPage";
import DescriptionPage1 from "./DescriptionPage1/DescriptionPage1";
import DescriptionPage2 from "./DescriptionPage2/DescriptionPage2";
import DowloadPage from "./DownloadPage/DownloadPage";
import PartnersPage from "./PartnersPage/PartnersPage";
import RoadmapPage from "./RoadmapPage/RodmapPage";
import TeamsPage from "./TeamsPage/TeamsPage";

function MainPage(){

    window.addEventListener('load', () => {
        
    });
    useEffect(() => {
        setTimeout(() => {
            if (window.location.href.indexOf('#') > -1)
            {
                const a = window.location.href.split('#')[1].replace('/', '');
                const elem = document.getElementById(a);
                elem?.scrollIntoView(true);
            }
        }, 100);
    }, [])
    return (
        <>
            <DowloadPage/>
            <DescriptionPage1/>
            <DescriptionPage2/>
            <TeamsPage/>
            <PartnersPage/>
            <AmbassadorPage/>
            <RoadmapPage/>
        </>
    )
}

export default MainPage;