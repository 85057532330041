import useIsMobile from "../../Hooks/useIsMobile";

export interface RenderOnProps {
    platform?: 'mobile' | 'desktop';
    condition?: boolean;
    children: React.ReactNode;
}

function RenderOn({platform, condition, children}: RenderOnProps) {
    const isMobile = useIsMobile();
    if (platform){
        return (platform === 'mobile' && isMobile) || (platform === 'desktop' && !isMobile)  ? <>{children}</> : <></>;
    }
    if (condition){
        return condition ? <>{children}</> : <></>;
    }
    return <></>;
}

export default RenderOn;