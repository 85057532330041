import styles from "./footer.module.scss";
import footer from '../../data/footer/footer.json';

function Footer() {
    return (
        <div style={{width: '100%'}}>
            <div className={styles.footer_stable}>
                <p>Terms of use</p>
                <p>Privacy Policy</p>
                <p>Processing of&nbsp;presonal data</p>
                <p>Community Rules</p>
                <p>Cookie Policy</p>
                <p>Security Policy</p>
                <p>Information about&nbsp;activities</p>
            </div>

            <div className={styles.footer_layout}>
                <div className={styles.footer_line}/>
                {footer.map(social => (
                    <>
                        <a className={styles.footer_icon} href={social.link}><img src={require('../../data/footer/icons/' + social.icon)} alt='Social'/></a>
                        <div className={styles.footer_line}/>
                    </>
                ))}
            </div>
        </div>
    );
}

export default Footer;