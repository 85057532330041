import styles from './downloadBox.module.scss';
import getGP from '../../../assets/icons/getOnGooglePlay.svg';
import downloadAS from '../../../assets/icons/donwloadOnAppStore.svg';
import downloads from '../../../data/downloads/downloads.json';

function DownloadBox(){
    return(
        <div className={styles.positioning_box}>
            <div className={styles.donwload_box} data-aos="fade-in">
                <p className={styles.download_text}>DOWNLOAD NOW</p>
                <div className={styles.buttons_holder}>
                    <a className={styles.button_link} href={downloads.android}><img src={getGP} alt='Google Play Icon'/></a>
                    <a className={styles.button_link} href={downloads.ios}><img src={downloadAS} alt='App Store Icon'/></a>
                </div>
            </div>
        </div>
    );
}

export default DownloadBox;
