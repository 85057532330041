import React, { CSSProperties } from 'react';
import styles from './teamsPage.module.scss';
import teamList from '../../data/team/team.json';
import HorizontalScrollMenu from '../../Components/HorizontalScrollMenu/HorizontalScrollMenu';
import { VisibilityContext } from 'react-horizontal-scrolling-menu';
import RenderOn from '../../Components/RenderOn/RenderOn';
import XLogo from '../../assets/icons/XLogo.svg';
import LinkedInLogo from '../../assets/icons/linkedinLogo.svg';


function LeftArrow(){
    const { isFirstItemVisible, scrollPrev } = React.useContext(VisibilityContext);
    return (<div className={styles.left_arrow} style={{opacity: isFirstItemVisible ? 0.6 : 1, cursor: isFirstItemVisible ? 'default' : 'pointer'}} onClick={() => scrollPrev()}/>)
}

function RightArrow(){
    const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);
    return (<div className={styles.right_arrow} style={{opacity: isLastItemVisible ? 0.6 : 1, cursor: isLastItemVisible ? 'default' : 'pointer'}} onClick={() => scrollNext()}/>)
}

const appearenceCount = 5; 
const textColors = ['white', 'white', 'black', 'black', 'black', 'white'];
const backgroundColors = ['#000000', "#0100FC", '#FFF201', '#FE0000', '#FF77B9'];

function PersonCard(props: {name: string, position: string, photo: string, index: number, x?: string, linkedIn?: string}){
    const bgc = backgroundColors[props.index % appearenceCount];
    const txtc = textColors[props.index % appearenceCount];
    return (
        <div className={styles.person_card} style={{'--bgc': bgc} as CSSProperties}>
            <div className={styles.person_card_photo} style={{backgroundImage: `url('${require("../../data/team/photos/" + props.photo)}')`} as CSSProperties}/>
            <p className={styles.person_card_name} style={{'--txtc': txtc} as CSSProperties}>{props.name.split(' ')[0]}<br/>{props.name.split(' ')[1]}</p>
            <p className={styles.person_card_position} style={{'--txtc': txtc} as CSSProperties}>{props.position}</p>
            <div className={styles.person_card_socials}>
                <div>
                    <RenderOn condition={props.x ? true : false}>
                        <a href={props.x}><img src={XLogo} alt='X'/></a>
                    </RenderOn>
                    <RenderOn condition={props.linkedIn ? true : false}>
                        <a href={props.linkedIn}><img src={LinkedInLogo} alt='LinkedIn'/></a>
                    </RenderOn>
                </div>
            </div>
        </div>
        )
}

function TeamsPage(){
    return(
        <div id='team' className={styles.page_container}>
            <div className={styles.main_container}>
                <div className={styles.title}>
                    <RenderOn platform='mobile'>
                        <div/>
                        <span>THE TEAM</span>
                        <div/>
                    </RenderOn>
                    <RenderOn platform='desktop'>
                        <span>THE TEAM</span>
                        <div/>
                        <span>THE TEAM</span>
                        <div/>
                        <span>THE TEAM</span>
                    </RenderOn>
                </div>
                <div className={styles.scroll_holder}>
                    <HorizontalScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
                        {teamList.concat(teamList).concat(teamList).concat(teamList).map((data, index) => (
                            <PersonCard name={data.name} position={data.position} photo={data.photo} index={index} linkedIn={data.linkedin} x={data.x}/>
                        ))}
                    </HorizontalScrollMenu>
                </div>
            </div>
        </div>
    );
}

export default TeamsPage;