import styles from './information.module.scss';
import RenderOn from '../../../Components/RenderOn/RenderOn';
import useIsMobile from '../../../Hooks/useIsMobile';

function Information(){
    const isMobile = useIsMobile();
    return(
        <div className={styles.information_box}>
            <div className={styles.info1_box} data-aos={isMobile ? "fade-left" : "fade-in"}>
                <p className={styles.info1_title}>Snap, Play, and Earn with Your Photos!</p>
                <p className={styles.info1_text}>Welcome to PICTOGRAPHS, where your photos transform into adventures, and rewards are just a picture away!</p>
            </div>

            <div className={styles.info2_box} data-aos="fade-right">
                <p className={styles.info2_title}>What’s PICTOGRAPHS?</p>
                <div className={styles.info2_inner_box}>
                    <div className={styles.info2_icon}/>
                    <p className={styles.info2_text1}>It’s not your average photo app; it’s a community-driven photo game that fuses social media with blockchain technology,</p>
                </div>
                <p className={styles.info2_text2}>spiced with financial incentives. The best part is you can earn real rewards for your photography skills and creativity!</p>
            </div>

            <RenderOn platform='desktop'>
                <div className={styles.info3_box} data-aos="fade-left">
                    <p className={styles.info2_title}>Why It’s Exceptional?</p>
                    <div className={styles.info2_inner_box}>
                        <p className={styles.info2_text1}>Say goodbye to regular photo apps! PICTOGRAPHS employs intelligent AI technology to make photo quests entertaining and</p>
                        <div className={styles.info3_icon}/>
                    </div>
                    <p className={styles.info2_text2}>straightforward. Each snap you take with Pictographs is like a strategic move in a game, tightly linked with in-game rewards. Get ready for an entirely new photography experience!</p>
                </div>
            </RenderOn>
        </div>
    );
}

export default Information;