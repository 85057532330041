import React from 'react';
import styles from './graphics.module.scss';
function Graphics(){
    return(
        <div className={styles.graphics_box}>
            <div className={styles.element1}/>
            <div className={styles.element2}/>
            <div className={styles.element3}/>
            <div className={styles.element4}/>
            <div className={styles.element5}/>
            <div className={styles.element6}/>
            <div className={styles.element7}/>
        </div>
    );
}

export default Graphics;