import styles from './headerButton.module.scss';

export interface HeaderButtonProps {
    children?: React.ReactNode;
    onClick?: () => void;
    href?: string;
}

function HeaderButton({children, onClick, href}: HeaderButtonProps) {
    return (
        <a className={styles.header_button} onClick={onClick} href={href}>{children}</a>
    )
}

export default HeaderButton;