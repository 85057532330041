import React from "react";
import styles from './ambassadorPage.module.scss';
import Graphics from "./Graphics/Graphics";
import Content from "./Content/Content";


function AmbassadorPage(){
    return(
        <div id="ambassadors" className={styles.page_container}>
            <Graphics/>
            <Content/>
        </div>
    )
}

export default AmbassadorPage;