import { useLayoutEffect, useState } from 'react';

export interface WindowSize{
  width: number,
  height: number
}

function useWindowSize() : WindowSize {
  const [size, setSize] = useState({width: 0, height: 0});
  useLayoutEffect(() => {
    const updateSize = () => setSize({width: window.innerWidth, height: window.innerHeight});
    updateSize();
    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

export default useWindowSize;