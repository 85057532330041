import React from 'react';
import styles from './partnersPage.module.scss';
import partners from '../../data/partners/partners.json';

function PartnersPage(){
    
    return(
        <div id='partners' className={styles.page_container}>
           <p className={styles.title} data-aos="fade-in">PARTNERS</p>
           <div className={styles.partners_container} data-aos="fade-up">
                {partners.map(partner => (
                    <a href={partner.link}>
                        <div className={styles.partner}
                        style={{backgroundImage: `url(${require('../../data/partners/icons/' + partner.icon)})`}}/>
                    </a>
                ))
                }
           </div>
        </div>
    );
}

export default PartnersPage;