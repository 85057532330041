import { ReactNode } from 'react';
import styles from './headerMainButton.module.scss';

export interface MainButtonProps {
    children: ReactNode;
    href: string;
}

function MainButton({ children, href }: MainButtonProps) {
    return (
        <a href={href} className={styles.main_button}>{children}</a>
    );
}

export default MainButton;