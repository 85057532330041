import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

function setupFirebase(){
    const firebaseConfig = {
        apiKey: "AIzaSyDd-YoKyht_o3GcAmMQS0bviNAZh_2-1KM",
        authDomain: "pictographs-website.firebaseapp.com",
        projectId: "pictographs-website",
        storageBucket: "pictographs-website.appspot.com",
        messagingSenderId: "955101072395",
        appId: "1:955101072395:web:3ca1b28adde201e61f0b0e",
        measurementId: "G-RTKPPY94GB"
    };

    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
    analytics.app.automaticDataCollectionEnabled = true;
}

export default setupFirebase;