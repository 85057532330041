import React from 'react';
import styles from './descriptionPage1.module.scss';
import Graphics from './Graphics/Graphics';
import Information from './Information/Information';

function DescriptionPage1(){
    return(
        <div id='description' className={styles.page_container}>
            <Graphics/>
            <Information/>
        </div>
    );
}

export default DescriptionPage1;