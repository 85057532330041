import React, { useState } from "react";
import styles from './content.module.scss';

function Content(){
    const [isVisible, setVisible] = useState(false);
    const toogleVisible = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {setVisible(!isVisible); e.stopPropagation()};
    const disableVisible = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {setVisible(false); e.stopPropagation()};
    return (
        <div className={styles.content_container} onClick={disableVisible}>
        <span className={styles.title} data-aos="fade-in">AMBASSADOR</span>
            <div className={styles.content_box} data-aos="zoom-in">
                <div className={styles.map}/>
                <div className={styles.map_back}/>
                <div className={styles.apply_point} onClick={toogleVisible}/>
                <div className={styles.apply_box} style={{display: isVisible ? 'flex' : 'none'}} onClick={e => e.stopPropagation()}>
                    <p>If you think you might be eligible<br/><br/>Send your application</p>
                    <a href="mailto:contact@pictographs.io">APPLY</a>
                </div>
            </div>
        </div>
    )
}

export default Content;