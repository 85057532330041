import styles from './roadmapPage.module.scss';
import Roadmap, { RoadmapNode } from '../../Components/Roadmap/Roadmap';
import roadmap from '../../data/roadmap/roadmap.json';
import HorizontalScrollMenu from '../../Components/HorizontalScrollMenu/HorizontalScrollMenu';
import Graphics from './Graphics/Graphics';

function RoadmapPage(){
    
    return(
        <div id='roadmap' className={styles.page_container}>
            <span className={styles.title} data-aos="fade-in">ROADMAP</span>
            <div className={styles.scroll_holder} data-aos="fade-up">
                <HorizontalScrollMenu LeftArrow={null} RightArrow={null}>
                    <Roadmap nodes={roadmap.nodes.map(x => x as RoadmapNode)} completed={roadmap.completed}/>
                </HorizontalScrollMenu>
            </div>
            <Graphics/>
        </div>
    );
}

export default RoadmapPage;