import React from 'react';
import styles from './downloadPage.module.scss';
import DownloadBox from './DownloadBox/DownloadBox';
import Graphics from './Graphics/Graphics';

function DowloadPage(){
    return(
        <div id='download' className={styles.page_container}>
            <DownloadBox/>
            <Graphics/>
        </div>
    );
}

export default DowloadPage;