import styles from './howToPlayPage.module.scss';

function HowToPlayPage(){
    return (
    <div className={styles.page_container}>
        <span className={styles.title}>VIDEO</span>
        <div className={styles.video_container}>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/q-9Jey0lUIA?si=GnhPNxtgwtpdCvMo" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen={true}/>
        </div>
    </div>);
}

export default HowToPlayPage;
