import React from 'react';
import styles from './header.module.scss';
import LogoDesktop from '../../assets/icons/logo.svg';
import LogoMobile from '../../assets/icons/logoMobile.svg';
import MenuIcon from '../../assets/icons/menuIcon.svg';
import CloseIcon from '../../assets/icons/closeIcon.svg';
import Dropdown from './Dropdown/Dropdown';
import header from '../../data/header/header.json';
import useIsMobile from '../../Hooks/useIsMobile';
import setScrollAllowed from '../../Hooks/setScrollAllowed';
import RenderOn from '../RenderOn/RenderOn';
import HeaderButton from '../HeaderButton/HeaderButton';
import MainButton from '../HeaderMainButton/HeaderMainButton';

function Logo() {
    const isMobile = useIsMobile();
    return (<a href='./'><img className={styles.header_logo} src={isMobile ? LogoMobile : LogoDesktop} alt='Logo' /></a>);
}

function ButtonsSeparator() {
    return <div className={styles.border_box} />;
}

function Header() {
    const [dropdownState, setDropdownState] = React.useState(false);
    const [mobileMenuState, setMobileMenuState] = React.useState(false);
    setScrollAllowed(!mobileMenuState);

    return (
        <div className={styles.header_layout}>
            <Logo/>
            <div className={styles.buttons_top_container}>
                <RenderOn platform='desktop'>
                    <div className={styles.small_buttons_container}>
                        <HeaderButton onClick={() => setDropdownState(!dropdownState)}>
                            HOME
                            <Dropdown state={dropdownState} buttons={header.home}/>
                        </HeaderButton>
                        <ButtonsSeparator />
                        { header.additional.map((button) => (
                            <>
                                <HeaderButton href={button.link}>{button.text}</HeaderButton>
                                <ButtonsSeparator />
                            </>
                        ))}
                    </div>
                </RenderOn>
                <MainButton href='https://app.pictographs.io/how-to-stake'>STAKING</MainButton>
                <RenderOn platform='mobile'>
                    <img className={styles.menu_icon} 
                        src={mobileMenuState ? CloseIcon : MenuIcon} 
                        alt='menu icon' 
                        onClick={() => setMobileMenuState(!mobileMenuState)}/>
                </RenderOn>
            </div>
            <RenderOn platform='mobile'>
                <div className={styles.menu_mobile} style={{display: mobileMenuState ? 'flex' : 'none'}}>
                    {[{text: 'HOME', link: './'}, ...header.additional].map((button) => (
                        <a href={button.link}>{button.text}</a>
                    ))}
                </div>
            </RenderOn>
        </div>
    );
}

export default Header;
