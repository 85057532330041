import styles from './roadmap.module.scss';
import useWindowSize from "../../Hooks/useWindowSize";

export type NodeType = 'title' | 'text';

export interface RoadmapNode{
    type: NodeType,
    data: string
}

export interface RoadmapProps{
    nodes: RoadmapNode[],
    completed: number
}

function Roadmap(props: RoadmapProps){
    const {nodes, completed} = props;
    const {height} = useWindowSize();
    const scaleFactor = (height * 0.5) / 450;
    console.log(scaleFactor);
    const unscaledWidth = 300 * (Math.floor(nodes.length / 3)) + 100;
    return (
        <div className={styles.top_container} style={{width: unscaledWidth * scaleFactor}}>
            <div className={styles.container} style={{width: unscaledWidth, transform: `scale(${scaleFactor})`}}>
                <div className={completed > 0 ? styles.top_circle : styles.top_circle_dashed} style={{left: -250}}/>
                {nodes.map(function (node, i) {
                    const goingFromBottom = i % 6 > 2;
                    const isCompleted = i + 1 <= completed;
                    const isLast = i === nodes.length - 1;
                    const nextIsCompleted = isLast ? false : i + 2 <= completed;
                    const leftOffset = 50 + 300 * (Math.floor(i / 3));
                    const topOffset = goingFromBottom ? 450 - (125 + 115 * (i % 3)): 95 + 115 * (i % 3);
                    return (
                        <>
                        <div className={isCompleted ? styles.completed_circle : styles.uncompleted_circle} style={{ top: topOffset, left: leftOffset }}/>
                        <span className={node.type === 'title' ? styles.title : styles.text} style={{top: topOffset, left: leftOffset}}>{node.data}</span>
                        {i % 6 === 5 ? <div className={isLast ? styles.top_last : nextIsCompleted ? styles.top_circle : styles.top_circle_dashed} style={{left: leftOffset}}/> :
                        i % 6 === 2 ? <div className={nextIsCompleted ? styles.bottom_circle : styles.bottom_circle_dashed} style={{bottom: 0, left: leftOffset}}/> : 
                        <div className={nextIsCompleted ? styles.vertical_line : styles.vertical_line_dashed} style={{top: topOffset + (goingFromBottom ? -90 : 25), left: leftOffset}}/>}
                        </>
                    );
                })}
            </div>
        </div>
    )
}

export default Roadmap;