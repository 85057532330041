import React from 'react';
import styles from './dropdown.module.scss';

export interface DropdownProps {
    buttons: {text: string, link: string}[];
    state: boolean;
}

function Dropdown(props: DropdownProps) {
  return (
    <div className={styles.dropdown_layout} style={{display: props.state ? 'flex' : 'none'}}>
        {props.buttons.map((button) =>  
            (
                <a href={button.link} className={styles.dropdown_button}>{button.text}</a>
            )
        )}
    </div>
  );
}

export default Dropdown;